.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom right, #e0f2fe, #dbeafe);
}

.title {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 1rem;
}

.buttonContainer {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 3rem;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
}

.card {
  flex: 1;
  min-width: 280px;
  max-width: 400px;
  padding: 1.5rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-decoration: none;
  color: #1e293b;
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border: 2px solid transparent;
}

.card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

/* Specific hover effects for each card */
.card:first-child {
  border-color: #3b82f6;
}

.card:last-child {
  border-color: #22c55e;
}

.card:first-child:hover {
  background-color: #e0f2fe;
  color: #2b6cb0;
  border-color: #2b6cb0;
}

.card:last-child:hover {
  background-color: #f0fff4;
  color: #2f855a;
  border-color: #2f855a;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .container {
    margin: 40px auto;
    padding: 0 16px;
  }

  .title {
    font-size: 2rem;
  }

  .buttonContainer {
    gap: 1rem;
    flex-direction: column;
    align-items: center;
  }

  .card {
    padding: 1.5rem;
    font-size: 1.1rem;
    min-width: unset;
    max-width: 100%;
  }

  .howItWorksSection {
    padding: 1rem !important;
    margin: 2rem auto !important;
  }

  .gridContainer {
    grid-template-columns: 1fr !important;
    gap: 1rem !important;
  }

  .stepCard {
    padding: 1rem !important;
  }

  .stepCard h3 {
    font-size: 1.1rem !important;
  }

  .stepCard p {
    font-size: 0.9rem !important;
  }

  .footer {
    padding: 1rem !important;
    font-size: 0.8rem !important;
  }
}

/* Small Mobile Devices */
@media (max-width: 480px) {
  .container {
    margin: 20px auto;
    padding: 0 12px;
  }

  .title {
    font-size: 1.8rem;
  }

  .card {
    padding: 1.25rem;
    font-size: 1rem;
  }

  .howItWorksSection {
    padding: 0.75rem !important;
  }

  .stepCard {
    padding: 0.75rem !important;
  }

  .stepCard h3 {
    font-size: 1rem !important;
  }

  .stepCard p {
    font-size: 0.85rem !important;
  }
} 